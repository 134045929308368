/* You can add global styles to this file, and also import other style files */

// Sizing
.w-fit-content {
  width: fit-content !important;
}

.h-fit-content {
  height: fit-content;
}

// Gap
.gap-0 {
  gap: 0;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 1rem;
}

.gap-4 {
  gap: 1.5rem;
}

.gap-5 {
  gap: 2rem;
}

.gap-6 {
  gap: 3rem;
}
